import React, { useState } from 'react';  
import Menu from './Menu';
import Modal from 'react-modal'; 
import logo from '../images/Logo.png'; 
import '../styles/menu.css';
import { useLocation } from 'react-router-dom';
Modal.setAppElement('#root'); 
  
const Header = ({rol}) => { 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const isLogin = location.pathname === '/Login';
  let userType = localStorage.getItem('userType'); 
 

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={isLogin  ? 'centered' : 'navigationbar_ai'}> 
        {isLogin === false && ( <div className='menuApp'> 
              <button onClick={openModal} className="dropdown-toggle">
                <div className="icon_outlinemenu">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 45" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.6001 11.5C6.6001 10.285 7.58507 9.3 8.8001 9.3H35.2001C36.4151 9.3 37.4001 10.285 37.4001 11.5C37.4001 12.715 36.4151 13.7 35.2001 13.7H8.8001C7.58507 13.7 6.6001 12.715 6.6001 11.5Z" fill="#FEF6F6"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.6001 22.5C6.6001 21.285 7.58507 20.3 8.8001 20.3H35.2001C36.4151 20.3 37.4001 21.285 37.4001 22.5C37.4001 23.715 36.4151 24.7 35.2001 24.7H8.8001C7.58507 24.7 6.6001 23.715 6.6001 22.5Z" fill="#FEF6F6"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.6001 33.5C6.6001 32.285 7.58507 31.3 8.8001 31.3H35.2001C36.4151 31.3 37.4001 32.285 37.4001 33.5C37.4001 34.715 36.4151 35.7 35.2001 35.7H8.8001C7.58507 35.7 6.6001 34.715 6.6001 33.5Z" fill="#FEF6F6"/>
                  </svg>
                </div>
              </button> 
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Menu Modal"
                className="modalMenu"
                overlayClassName="overlayMenu"
              >
                <Menu 
                  isPopupOpen={isModalOpen}
                  closePopup={closeModal}
                />
              </Modal>
          </div>)} 
        <div className="logo_title">
          <a href ="https://getyouniq.com/" target='_blank' >
            <div className="app_icon">
              <img src={logo} alt="Application Logo" className="app_icon" />
            </div>
          </a>
        </div>
        {userType === '1'  && isLogin===false  &&
         (<div className="userphoto"  />) }
      </div>
    
  );
};

export default Header;
