import React, { useState, useEffect } from 'react'; 
import MyCreationImages from '../components/MyCreationImages';    
import Multiselect from 'multiselect-react-dropdown'; 
import { useNavigate, useLocation } from 'react-router-dom'; 
import Alert from '../components/Alert';
import Modal from 'react-modal'; 
import '../styles/multiselect-custom.css';
 
Modal.setAppElement('#root');  

export default function MyCreation() {
  return (  
      <MainComponent /> 
  );
};

const MainComponent = () => {   
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [FilterArray, setFilterArray] = useState([]);
  const [UserIdQuery, setUserIdQuery] = useState(1);  
  const navigate = useNavigate();
  const handleMyStudioClick = () => { 
    navigate('/MyStudio');
  };
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [DataCount, setDataCount] = useState(0);

  const location = useLocation();  
  const { refreshState, isTitle, isMessage,isStatus } = location.state || {};
  
  useEffect(() => {
    document.title = "YOUNIQ - My Creation";
  }, []);

  useEffect(() => {
    if (isTitle && isMessage) {
        setShouldOpenModal(true);
    }
  }, [isTitle, isMessage, navigate, location.pathname]);

  useEffect(() => {
    if (shouldOpenModal) {
       openModal();
       setShouldOpenModal(false);
    }
  }, [shouldOpenModal]);

  const openModal = () => {
    setIsModalOpen(true);
    setTimeout(() => {
        closeModal();
    }, 3000);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    navigate(location.pathname, { replace: true, state: {} });
  };
   
  const handleOptionChange = (selectedList, selectedItem) => {
    console.log('Selected:', selectedList);
    setSelectedOptions(selectedList); 
    setFilterArray(selectedList.map(item => item.value));

  };

  const onRemove = (selectedList, removedItem) => {
    console.log('Removed:', selectedList);
    setSelectedOptions(selectedList);
    //alert(FilterArray)
    setFilterArray(selectedList.map(item => item.value));     
  };

  const [statusNames, setStatusNames] = useState([]);
  const onDataFetchFilters = (statusNames) => {
    setStatusNames(statusNames);
  };

  const options = statusNames.map((statusName) => ({
    value: statusName,
    label: statusName,
  }));
  
  const onDataCount = (count) => {
    setDataCount(count);
  };
  
  return ( 
    <div className='content'>  
      <div className='MyCreationContent'>
        <div className='navbar_creations'>
          <button className='showAll_button' onClick={handleMyStudioClick}>
            <div className='button_createpost'>
              <div className='button_back_appIcon'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 36" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9874 26.2374C16.304 26.9209 15.196 26.9209 14.5126 26.2374L7.51256 19.2374C6.82915 18.554 6.82915 17.446 7.51256 16.7626L14.5126 9.76256C15.196 9.07915 16.304 9.07915 16.9874 9.76256C17.6709 10.446 17.6709 11.554 16.9874 12.2374L12.9749 16.25L26.25 16.25C27.2165 16.25 28 17.0335 28 18C28 18.9665 27.2165 19.75 26.25 19.75H12.9749L16.9874 23.7626C17.6709 24.446 17.6709 25.554 16.9874 26.2374Z" fill="white"/>
                </svg>
              </div>
              <div className='mystudioback_label'>
                My Studio
              </div>
            </div>
          </button>
          
          <div className='mycreationFilterBox'>
            <div className='mycreationText'> 
              Filter by 
            </div>
            <div >
              <Multiselect
                options={options}
                selectedValues={selectedOptions}
                onSelect={handleOptionChange}
                onRemove={onRemove}
                displayValue="label"
                placeholder={`ALL (${DataCount})`}   
                closeOnSelect={true}
                showCheckbox={true} 
                showArrow={true}
                className="multiselect-container" 
                hideSelectedList
                style={{
                  borderRadius: '8px',  
                  border: 'none'    
                }}
              />
              <span className="custom-arrowS">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55754 8.15764C5.96759 7.74759 6.63241 7.74759 7.04246 8.15764L10.5 11.6152L13.9575 8.15764C14.3676 7.74758 15.0324 7.74758 15.4425 8.15764C15.8525 8.56769 15.8525 9.23251 15.4425 9.64256L11.2425 13.8426C10.8324 14.2526 10.1676 14.2526 9.75754 13.8426L5.55754 9.64256C5.14749 9.23251 5.14749 8.56769 5.55754 8.15764Z" fill="#FEF6F6"/>
                  </svg>
              </span>
            </div>
          </div> 
        </div>
        
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Upload Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <Alert 
            isPopupOpen={isModalOpen}
            closePopup={closeModal} 
            title={isTitle}
            message={isMessage}
            status={isStatus} 
          />
        </Modal>
        
        <MyCreationImages 
          UserIdQuery={UserIdQuery} 
          PlatformQuery='Big'  
          FilterArray={FilterArray} 
          onDataFetchFilters={onDataFetchFilters} 
          onDataCount={onDataCount} 
          refresh= {refreshState}
        />
      </div>
    </div>
  )
}
